import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageFiveDesktop from "./images/imagesDesktop/imageFive.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"
import imageFiveMobile from "./images/imagesMobile/imageFive.webp"



const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Potencia tus estrategias de lead generation",
      description: "Crea un ecosistema integrado para capturar leads en múltiples canales e implementa workflows que automaticen el contacto y el seguimiento de tus clientes potenciales, optimizando así tu proceso de ventas y un contacto personalizado en cada etapa de conversión.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Sigue el ciclo de vida del cliente",
      description: "Captura datos del cliente desde el primer contacto hasta la conversión y utilízalos para analizar e identificar puntos críticos, oportunidades de mejora y estrategias para aumentar la retención y la lealtad del cliente. Evalúa el recorriendo de tus leads, ya sean orgánicos o por Ads.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Analiza tus resultados en tiempo real",
      description: "Monitorea el rendimiento de las campañas a través de paneles de control y reportes en tiempo real. Identifica rápidamente tendencias y patrones emergentes para realizar ajustes y optimizar las estrategias sobre la marcha.",
      image: imageThreeMobile,
      background: "#EEEAFB"
    },
    {
      title: "Generación de conexiones verdaderas",
      description: "Utiliza datos de múltiples canales y analízalos con IA para comprender mejor las necesidades, preferencias y comportamientos de tus clientes. Así, personalizarás las interacciones con ellos en todos los puntos de contacto y mejorarás tu tasa de conversión y fidelización.",
      image: imageFourMobile,
      background: "#FBE6E5"
    },
    {
      title: "Agilización de ventas",
      description: "Facilita el proceso de ventas proporcionando a los equipos comerciales acceso rápido a información actualizada sobre productos, inventario, precios y clientes desde cualquier canal. Automatiza tareas administrativas para que los agentes puedan centrarse en cerrar más ventas.",
      image: imageFiveMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-marketing">
      <p className="container-experience-marketing-title">
      <TitleUnderlineOne underline="Mejora las" /> <TitleUnderlineTwo underline="interacciones" /> con tus clientes con conexiones automatizadas que agilizan y personalizan cada punto de contacto
      </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-marketing-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-marketing-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-marketing-cardsOne-card-title">Potencia tus estrategias de lead generation</p>
            <p className="container-experience-marketing-cardsOne-card-description">
            Crea un ecosistema integrado para capturar leads en múltiples canales e implementa workflows que automaticen el contacto y el seguimiento de tus clientes potenciales, optimizando así tu proceso de ventas y un contacto personalizado en cada etapa de conversión.
            </p>
          </section>
          <section>
            <img
              className="container-experience-marketing-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-marketing-cardsOne-column">
          {/* columna */}
          <section className="container-experience-marketing-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px 0px" }}>
            <section style={{ padding: "0px 0px 0px 32px" }}>
              <p className="container-experience-marketing-cardsOne-card-title">Sigue el ciclo de vida del cliente</p>
              <p className="container-experience-marketing-cardsOne-card-description">
              Captura datos del cliente desde el primer contacto hasta la conversión y utilízalos para analizar e identificar puntos críticos, oportunidades de mejora y estrategias para aumentar la retención y la lealtad del cliente. Evalúa el recorriendo de tus leads, ya sean orgánicos o por Ads.
              </p>
            </section>
            <section>
              <img
                className="container-experience-marketing-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
          {/* columna */}
          <section className="container-experience-marketing-cardsOne-card" style={{ background: "#D3F7FA" }}>
            <section>
              <p className="container-experience-marketing-cardsOne-card-title">Analiza tus resultados en tiempo real</p>
              <p className="container-experience-marketing-cardsOne-card-description">
              Monitorea el rendimiento de las campañas a través de paneles de control y reportes en tiempo real. Identifica rápidamente tendencias y patrones emergentes para realizar ajustes y optimizar las estrategias sobre la marcha. </p>
            </section>
            <section>
              <img
                className="container-experience-marketing-cardsOne-card-image"
                src={imageThreeDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-marketing-cardsTwo">
        {/* columna */}
        <div className="container-experience-marketing-cardsTwo-cards-column">
          <section className="container-experience-marketing-cardsTwo-card" style={{ background: "#EEEAFB" }}>
            <section>
              <p className="container-experience-marketing-cardsTwo-card-title">Generación de conexiones verdaderas</p>
              <p className="container-experience-marketing-cardsTwo-card-description">
              Utiliza datos de múltiples canales y analízalos con IA para comprender mejor las necesidades, preferencias y comportamientos de tus clientes. Así, personalizarás las interacciones con ellos en todos los puntos de contacto y mejorarás tu tasa de conversión y fidelización.
              </p>
            </section>
            <section>
              <img
                className="container-experience-marketing-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-marketing-cardsTwo-card" style={{ background: "#FBE6E5", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-marketing-cardsTwo-card-title">Agilización de ventas</p>
            <p className="container-experience-marketing-cardsTwo-card-description">
            Facilita el proceso de ventas proporcionando a los equipos comerciales acceso rápido a información actualizada sobre productos, inventario, precios y clientes desde cualquier canal. Automatiza tareas administrativas para que los agentes puedan centrarse en cerrar más ventas.</p>
          </section>
          <section>
            <img
              className="container-experience-marketing-cardsTwo-card-image"
              src={imageFiveDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>

      <div className="container-experience-marketing-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-marketing-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-marketing-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-marketing-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-marketing-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
