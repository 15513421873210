import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="200" height="10" viewBox="0 0 200 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.1831 10C33.0482 9.07169 2.7714 10.1081 0.570702 9.82831C-1.22596 9.45317 1.64526 2.84689 3.31298 1.87407C3.89754 1.52436 4.57666 1.47351 6.13263 1.63247C8.59982 1.93131 40.5014 1.33997 40.5014 1.33997C40.9226 1.54344 42.1863 0.856744 42.4184 1.10472C43.1491 1.68332 94.8569 0.894891 99.2669 0.8631C101.94 0.844025 99.8257 1.23824 102.972 1.07928C109.677 0.761364 124.291 1.06657 129.372 0.704142C133.438 0.405302 134.315 0.392594 138.218 0.63421C142.206 0.850392 163.414 0.538832 165.838 0.074676C166.431 -0.0524901 166.887 -0.00797814 166.887 0.138263C166.887 0.170054 181.836 0.278142 181.905 0.392591C182.017 0.538832 182.472 0.608769 182.894 0.507036C183.676 0.354437 199.588 -0.198737 199.837 0.227269C200.619 1.44806 198.427 6.64915 196.579 7.91446C194.688 9.26878 184.303 7.74915 175.956 8.56302L137.091 8.64568L128.761 9.15433C127.807 8.71561 124.137 9.82196 121.919 9.03353C120.982 8.72197 114.362 8.67745 112.901 9.00809C111.629 9.26242 77.7671 8.62023 74.2941 9.50403C68.1047 8.76647 43.0029 9.82197 37.1831 10Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="200" height="10" viewBox="0 0 200 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.1831 10C33.0482 9.07169 2.7714 10.1081 0.570702 9.82831C-1.22596 9.45317 1.64526 2.84689 3.31298 1.87407C3.89754 1.52436 4.57666 1.47351 6.13263 1.63247C8.59982 1.93131 40.5014 1.33997 40.5014 1.33997C40.9226 1.54344 42.1863 0.856744 42.4184 1.10472C43.1491 1.68332 94.8569 0.894891 99.2669 0.8631C101.94 0.844025 99.8257 1.23824 102.972 1.07928C109.677 0.761364 124.291 1.06657 129.372 0.704142C133.438 0.405302 134.315 0.392594 138.218 0.63421C142.206 0.850392 163.414 0.538832 165.838 0.074676C166.431 -0.0524901 166.887 -0.00797814 166.887 0.138263C166.887 0.170054 181.836 0.278142 181.905 0.392591C182.017 0.538832 182.472 0.608769 182.894 0.507036C183.676 0.354437 199.588 -0.198737 199.837 0.227269C200.619 1.44806 198.427 6.64915 196.579 7.91446C194.688 9.26878 184.303 7.74915 175.956 8.56302L137.091 8.64568L128.761 9.15433C127.807 8.71561 124.137 9.82196 121.919 9.03353C120.982 8.72197 114.362 8.67745 112.901 9.00809C111.629 9.26242 77.7671 8.62023 74.2941 9.50403C68.1047 8.76647 43.0029 9.82197 37.1831 10Z" fill="#FFA700" />
    </svg>
  </span>
)
