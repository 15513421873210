import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"

const NavbarVertical = ({ dataOne, dataTwo, dataSilder, description, title, objOne, objTwo }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const dotsClassDefault = "slick-dots" // clase por defecto de la libreria
  const dotsClassCustom = "slick-dots-slider-tabs"

  const activateSlider = {
    customPaging: function (i) {
      const tab = i => dataSilder[i].tab

      return <button>{tab(i)}</button>
    },
    slidesToShow: 1,
    infinite: true,
    initialSlide: 0,
    dots: true,
    speed: 500,
    dotsClass: `${dotsClassDefault} ${dotsClassCustom}`,
    afterChange: function (index) {
      const dots = document.querySelector(`.${dotsClassCustom}`)
      dots.scrollLeft = 100 * index
    },
  }



  //estado para el texto de la caja en desktop
  const [textRight, settextRight] = useState([objOne])
  const supportRight = (event, text) => {
    event.preventDefault()
    settextRight([text])
  }

  //estado para el texto de la caja en desktop
  const [textLeft, settextLeft] = useState([objTwo])
  const supportLeft = (event, text) => {
    event.preventDefault()
    settextLeft([text])
  }




  //Activar estilo para primer boton al cargar la pagina
  useLayoutEffect(() => {
    var header = document.getElementById("myDIVnavbarVerticalRight")
    var btns = header.getElementsByClassName("container-navbar-vertical-right-navbar-menu-button")
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("container-navbar-vertical-right-navbar-menu-button-active")
        current[0].className = current[0].className.replace("container-navbar-vertical-right-navbar-menu-button-active", "")
        this.className += " container-navbar-vertical-right-navbar-menu-button-active"
      })
    }
  })
  //Activar estilo para primer boton al cargar la pagina
  useLayoutEffect(() => {
    var header = document.getElementById("myDIVnavbarVerticalLeft")
    var btns = header.getElementsByClassName("container-navbar-vertical-left-navbar-menu-button")
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("container-navbar-vertical-left-navbar-menu-button-active")
        current[0].className = current[0].className.replace("container-navbar-vertical-left-navbar-menu-button-active", "")
        this.className += " container-navbar-vertical-left-navbar-menu-button-active"
      })
    }
  })

  return (
    <section className="container-navbar-vertical">
      <p className="container-navbar-vertical-title">
        {title}
      </p>
      <div>{description}
      </div>

      <section id="myDIVnavbarVerticalRight" className="container-navbar-vertical-right-navbar">
        <ul className="container-navbar-vertical-right-navbar-menu">
          {dataOne.map((elemento, i) => {
            return (
              <li key={i}>
                <button
                  className={`container-navbar-vertical-right-navbar-menu-button
                  ${elemento.active}`}
                  onClick={event => {
                    supportRight(event, elemento)
                  }}
                >
                  <p className="container-navbar-vertical-right-navbar-menu-button-tab"> {elemento.tab}</p>
                  <div>
                    <p className="container-navbar-vertical-right-navbar-menu-button-title">
                      {elemento.title}  </p>
                    <span>{elemento.text}</span>
                  </div>
                </button>
              </li>
            )
          })}
        </ul>
        <div className="container-navbar-vertical-right-navbar-info">
          <img src={textRight[0].image} alt="" />
        </div>
      </section>

      <section id="myDIVnavbarVerticalLeft" className="container-navbar-vertical-left-navbar">

        <div className="container-navbar-vertical-left-navbar-info">
          <img src={textLeft[0].image} alt="" />
        </div>
        <ul className="container-navbar-vertical-left-navbar-menu">
          {dataTwo.map((elemento, i) => {
            return (
              <li key={i}>
                <button
                  className={`container-navbar-vertical-left-navbar-menu-button
                  ${elemento.active}`}
                  onClick={event => {
                    supportLeft(event, elemento)
                  }}
                >
                  <p className="container-navbar-vertical-left-navbar-menu-button-tab"> {elemento.tab}</p>
                  <div>
                    <p className="container-navbar-vertical-left-navbar-menu-button-title">
                      {elemento.title}  </p>
                    <span>{elemento.text}</span>
                  </div>
                </button>
              </li>
            )
          })}
        </ul>
      </section>

      <div ref={fromRef} className="container-navbar-vertical-silder">
        <Slider {...activateSlider} ref={sliderRef}>
          {dataSilder.map((selectElement, i) => {
            return (
              <div key={i} className="container-navbar-vertical-silder-item">
                <div>
                  <p className="container-navbar-vertical-silder-item-title">
                    {selectElement.title}
                  </p>
                  <p className="container-navbar-vertical-silder-item-text">
                    {selectElement.text}
                  </p>
                </div>
                <img src={selectElement.image} alt="" />
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default NavbarVertical
