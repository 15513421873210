/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Chiolecca from "@components/sharedComponents/customersLogos/logos/Chiolecca.svg"
import LaMatier from "@components/sharedComponents/customersLogos/logos/LaMatier.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/tecnicas-negociacion-empresarial.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/tendencias-ventas.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/automatizar-negocio.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/que-es-tasa-interna-de-retorno.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/como-mejorar-cierre-ventas.jpg"


// logos testimonios
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"
import CulquiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"
import CostagasTes from "@components/sharedComponents/testimonies/images/logos/costagas.svg"

//foto testimonio desktop
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"
import costagasDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/costagas.png"

//foto testimonio desktop
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"
import costagasMobile from "@components/sharedComponents/testimonies/images/fotosMobile/costagas.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageCasosdeUso/pageMarketing/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageCasosdeUso/pageMarketing/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageCasosdeUso/pageMarketing/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageCasosdeUso/pageMarketing/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageCasosdeUso/pageMarketing/images/accordionLeft/imageFive.webp"


//image indrustias
import banca from "@components/pageCasosdeUso/components/industries/images/banca.png"
import salud from "@components/pageCasosdeUso/components/industries/images/salud.png"
import ecommerce from "@components/pageCasosdeUso/components/industries/images/ecommerce.png"
import educacion from "@components/pageCasosdeUso/components/industries/images/educacion.png"
import concesionaria from "@components/pageCasosdeUso/components/industries/images/concesionaria.png"
import contact from "@components/pageCasosdeUso/components/industries/images/contact.png"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageMarketingVentas.jpg"


import { TitleUnderlineMarketingOne, TitleUnderlineMarketingTwo } from "@components/pageCasosdeUso/components/intro/titleUnderlineMarketing"
import imageStartMarketing from "@components/pageCasosdeUso/components/intro/images/imageStartMarketing.webp"

import ImageVerticalOne from "@components/pageCasosdeUso/pageMarketing/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageCasosdeUso/pageMarketing/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalThree from "@components/pageCasosdeUso/pageMarketing/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageCasosdeUso/pageMarketing/images/imagesNavbarVertical/imageFour.webp"

const titleStart = (
  <p>
    Crea experiencias personalizadas que te ayuden a <TitleUnderlineMarketingOne underline="vender más" /> y <TitleUnderlineMarketingTwo underline="mejorar tu ROI" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Potencia la interacción con tus clientes en cada fase de tu embudo de ventas. Prospecta mejor a tus leads con chatbots y automatizaciones. Hazlo por el canal preferido de tus contactos, como WhatsApp, correo y llamadas.
        <br /><br />
        Utiliza la IA para conocer las preferencias de tus clientes y así aplicar mejoras en tus estrategias. Además, optimiza la productividad de tus agentes reduciendo tareas repetitivas.

      </p>
    ),
    image: imageStartMarketing,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqi,
    Auna,
    Astara,
    Alfin,
    Unifef,
    Proempresa,
    Synlab,
    Prestamype,
    Chiolecca,
    LaMatier,
    CasaRossello
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqi, Auna, Astara, Alfin, Unifef],
    arrayTwo: [Proempresa, Synlab, Prestamype, Chiolecca, LaMatier, CasaRossello],
  },
  articles: [
    {
      image: article1,
      title: "10 técnicas de negociación empresarial para vender más",
      textbody:
        "Conoce 10 técnicas de negociación empresarial para impulsar tus ventas. ¡Entra aquí y cierra tratos beneficiosos!",
      link: "https://beexcc.com/blog/tecnicas-negociacion-empresarial/",
      card: true,
    },
    {
      image: article2,
      title: "10 tendencias de ventas 2024 que te ayudarán a mejorar tus conversiones",
      textbody:
        "Sumérgete en las 10 tendencias de ventas 2024 que impulsarán el crecimiento de tu negocio.",
      link: "https://beexcc.com/blog/tendencias-ventas/",
      card: true,
    },
    {
      image: article3,
      title: "Descubre cómo automatizar un negocio en 7 pasos",
      textbody:
        "Descubre cómo automatizar un negocio en 7 pasos y qué plataformas usar. ¡Entra aquí y transforma tu empresa con las mejores prácticas!",
      link: "https://beexcc.com/blog/automatizar-negocio/",
    },
    {
      image: article4,
      title: "Tasa interna de retorno (TIR): definición, fórmula y ejemplos",
      textbody:
        "Descubre qué es y cómo se calcula la tasa interna de retorno. ¡Entra aquí y aprende paso a paso cómo maximizar tus inversiones!",
      link: "https://beexcc.com/blog/que-es-tasa-interna-de-retorno/",
    },
    {
      image: article5,
      title: "¿Cómo mejorar el cierre de ventas? 10 estrategias y ejemplos",
      textbody:
        "Descubre cómo mejorar el cierre de ventas con estas 10 estrategias y ejemplos. ¡Entra aquí y consigue más clientes para tu negocio!",
      link: "https://beexcc.com/blog/como-mejorar-cierre-ventas/",
    },
  ],
  contacts: {
    title: "Aumenta tus ventas y la satisfacción al cliente",
    textbody: "Cuéntanos sobre los procesos de marketing y ventas de tu empresa y nuestro equipo te brindará un feedback detallado para potenciar tus operaciones.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CasaRosselloTes,
      title:
        "Con Beex, hemos aumentado en +75% nuestros leads desde canal digital",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing de Casa Rosselló",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AstaraTes,
      title:
        "Con Beex, triplicamos nuestras ventas y reducido el esfuerzo de remarketing en 93%",
      textbody:
        "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
      name: "Jonny Castillo",
      post: "Jefe Comercial de Astara",
      link: "/casos-de-exito/astara/",
      imgDesktop: astaraDesktop,
      imgMobile: astaraMobile,
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#FFB800",
        },
      ],
    },
    {
      image: CulquiTes,
      title:
        "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
      textbody:
        "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
      name: "Paul Vera",
      post: "Jefe de Telemarketing de Culqi",
      link: "/casos-de-exito/culqi/",
      imgDesktop: culqiDesktop,
      imgMobile: culqiMobile,
      metrics: [
        {
          metric: "+65%",
          descripcion: "mejoró su contactabilidad con sus leads",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "aumentó sus ventas mensuales",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de bases",
          color: "#FFB800",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "Con Beex y el API de WhatsApp mejoramos nuestra tasa de conversión de leads en +27%",
      textbody:
        "CEPEBAN, instituto especializado en banca y finanzas, ha aumentado a +1.5k la cantidad de leads mejor prospectados con chatbots por WhatsApp Business API.",
      name: "Alan De La Cruz",
      post: "Jefe de Sistemas de CEPEBAN",
      link: "/casos-de-exito/cepeban/",
      imgDesktop: cepebanDesktop,
      imgMobile: cepebanMobile,
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejoró su tasa de conversión con sus leads",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads prospectados al mes por chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#FFB800",
        },
      ],
    },
    {
      image: CostagasTes,
      title: " Beex nos ha permitido reducir el tiempo de espera hasta en <1 minuto",
      textbody:
        "Costagas, compañía con más de 40 años en el sector energético, comercializa sus servicios de consumo masivo con Beex. Logrando mejorar sus ventas en 1.3x",
      name: "Natalia Pachas",
      post: "Jefe de Marketing en Costagas",
      link: "/casos-de-exito/costagas/",
      imgDesktop: costagasDesktop,
      imgMobile: costagasMobile,
      metrics: [
        {
          metric: "1.3x",
          descripcion: "aumento de ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "tiempo de primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "-40%",
          descripcion: "reducción de abandono",
          color: "#FFB800",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionRight: [
    {
      title: "Crea una estrategia de marketing unificada",
      description: "<p>Unifica tus canales de contacto y crea automatizaciones con ellos para mejorar su gestión. Ya sea por llamadas, WhatsApp, correo o SMS. Configura workflows que permitan un viaje del cliente placentero y sin fricciones, que permitan elevar tu ticket de compra.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Mejora el ROI de tus anuncios",
      description: "<p>Utiliza chatbots con IA en tus anuncios para mejorar tu proceso de prospección. Así, maximizas la efectividad de tus ads al dirigir a tus leads hacia conversiones relevantes. Ofrece respuestas rápidas a consultas frecuentes, reduciendo el volumen de consultas y mejorando la experiencia del usuario.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Obtén mejores resultados cross-selling y upselling",
      description: "<p>Identifica oportunidades de cross-selling y upselling para presentarlas de manera oportuna durante las interacciones con el cliente. Implementa recomendaciones personalizadas basadas en el historial del comportamiento del cliente en diferentes canales.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Automatiza el envío de notificaciones",
      description: "<p>Configura flujos de trabajo automatizados para enviar notificaciones personalizadas a los clientes en momentos clave como confirmaciones de compra. Utiliza canales cercanos como WhatsApp para para alcanzar a los clientes de manera efectiva según sus preferencias individuales.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Integra tus herramientas internas",
      description: "<p>Conecta tus softwares preferidos, como Salesforce, HubSpot, Zoho, Shopify, VTEX y más. Permite a los equipos de marketing y ventas acceder fácilmente a la información relevante y tomar decisiones informadas sin problemas entre diferentes herramientas y plataformas.</p>",
      image: ImageAccordionLeftFive
    },
  ],

  industries: [
    {
      tab: "01. E-commerce",
      title: "Plataforma de atención al cliente para E-commerce y Retail",
      text: (
        <p>
          <br />
          Facilita la experiencia del cliente mediante un autoservicio para la gestión de pedidos, seguimiento de envíos y resolución de consultas frecuentes. Optimiza la interacción digital con los usuarios, mejorando la satisfacción y fidelización del cliente.
          <br /> <br /> <br />
        </p>
      ),
      image: ecommerce,
      active: "container-industries-five-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Mejora tu CX en E-commerce",
    },
    {
      tab: "02. Banca y finanzas",
      title: "Plataforma de atención al cliente para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Ofrece un autoservicio integral para la gestión de cuentas, transferencias y consultas financieras. Mejora la accesibilidad y seguridad en las transacciones digitales, fortaleciendo la confianza del cliente con tu banco, seguro o fintech.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Banca y Finanzas",
    },
    {
      tab: "03. Salud",
      title: "Plataforma de atención al cliente para Clínicas, Hospitales y Centros de Salud",
      text: (
        <p>
          <br />
          Implementa un autoservicio para la gestión de citas, consultas frecuentes y disponibilidad de médicos en tu clínica u hospital. Mejora la atención digital con el paciente y aumenta la credibilidad de tu institución al ofrecer una experiencia más conveniente y eficiente para los usuarios.
          <br /> <br /><br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Salud",
    },
    {
      tab: "04. Educación",
      title: "Plataforma de atención al cliente para Universidades, Institutos y Colegios",
      text: (
        <p>
          <br />
          Proporciona una experiencia omnicanal para la gestión de matrículas, consultas académicas y seguimiento del progreso estudiantil. Incrementa la eficiencia administrativa y la comunicación entre instituciones educativas, profesores y alumnos.
          <br /> <br /><br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Eduación",
    },
    {
      tab: "05. Concesionarias",
      title: "Plataforma de atención al cliente para Concesionarias",
      text: (
        <p>
          <br />
          Automatiza la gestión de citas de prueba de vehículos, consultas sobre modelos y disponibilidad de stock. Optimiza la experiencia del cliente en línea, generando un mayor interés y confianza en la marca automotriz.
          <br /> <br /><br />
        </p>
      ),
      image: concesionaria,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp API para Inmobiliarias",
    },
    {
      tab: "06. Contact Center",
      title: "Plataforma de atención al cliente para BPOs y Contact Center",
      text: (
        <p>
          <br />
          Despliega un sistema integrado para la gestión de consultas, seguimiento de casos y soporte técnico. Mejora la eficiencia operativa y la satisfacción del cliente al proporcionar respuestas rápidas y precisas a través de múltiples canales de comunicación.
          <br /> <br /><br />
        </p>
      ),
      image: contact,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp API  para Contact Center",
    },
  ],
  bannerVideo: {
    title: "Cómo automatizar procesos en una empresa: 5 consejos claves",
    description: "<p>Descubre cómo optimizar la eficiencia de tu empresa con estos 5 consejos esenciales para la automatización de procesos. Simplifica operaciones, aumenta la productividad y libera tiempo para lo que realmente importa. <br /> <br /> Nuestro Project Manager, Jheyson Portocarrero, nos cuenta todos los detalles para automatizar cada proceso de tu empresa.</p>",
    image: imageVideo
  },
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Notificaciones en cada proceso de compra",
      text: (
        <p>
          Desde la confirmación del pedido hasta la actualización del estado del envío y la entrega. Mantén informado y comprometidos a tus clientes durante todo el proceso de compra de forma automatizada, logrando mejorar su experiencia y aumentar su satisfacción.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Mejora de anuncios Click-to-WhatsApp",
      text: (
        <p>
          Aumenta las interacciones y las conversiones de tus anuncios al proporcionar un autoservicio en tu canal de WhatsApp con chatbots con ChatGPT. De este modo, reduces los grandes volúmenes de consultas repetitivas provenientes de tus anuncios.
        </p>
      ),
      image: ImageVerticalTwo,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "3",
      title: "Envío de mensajes masivos",
      text: (
        <p>
          Crea en simples pasos el envío de mensajes masivos a grupos de clientes segmentados sobre promociones especiales, eventos de venta, lanzamientos de productos y otras campañas de marketing destinadas a llegar a una audiencia más amplia.
        </p>
      ),
      image: ImageVerticalThree,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "4",
      title: "Envío de encuestas de satisfacción",
      text: (
        <p>
          Envía encuestas después de una compra o experiencia de servicio. Recopila comentarios valiosos sobre la calidad de tus productos o servicios. Resume y audita conversaciones con IA y logra identificar áreas de mejora para aumentar la satisfacción y la fidelidad a la empresa.
        </p>
      ),
      image: ImageVerticalFour,
    },

  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Notificaciones en cada proceso de compra",
      text: (
        <p>
          Desde la confirmación del pedido hasta la actualización del estado del envío y la entrega. Mantén informado y comprometidos a tus clientes durante todo el proceso de compra de forma automatizada, logrando mejorar su experiencia y aumentar su satisfacción.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Mejora de anuncios Click-to-WhatsApp",
      text: (
        <p>
          Aumenta las interacciones y las conversiones de tus anuncios al proporcionar un autoservicio en tu canal de WhatsApp con chatbots con ChatGPT. De este modo, reduces los grandes volúmenes de consultas repetitivas provenientes de tus anuncios.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Envío de mensajes masivos",
      text: (
        <p>
          Crea en simples pasos el envío de mensajes masivos a grupos de clientes segmentados sobre promociones especiales, eventos de venta, lanzamientos de productos y otras campañas de marketing destinadas a llegar a una audiencia más amplia.
        </p>
      ),
      image: ImageVerticalThree,
    },
    {
      tab: "4",
      title: "Envío de encuestas de satisfacción",
      text: (
        <p>
          Envía encuestas después de una compra o experiencia de servicio. Recopila comentarios valiosos sobre la calidad de tus productos o servicios. Resume y audita conversaciones con IA y logra identificar áreas de mejora para aumentar la satisfacción y la fidelidad a la empresa.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
  ],
}

export default data
